.travels{

}
.travel-container{
    padding: 2rem 3rem 4rem;
}

@media screen and (max-width: 960px) {
    .travel-container{
        padding: 2rem 1rem 4rem;
    }
}
@media screen and (max-width: 500px) {
    .travel-container{
        padding: 1.5rem 1rem 4rem;
    }
}