
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Syne:wght@400..800&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'aos/dist/aos.css';

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth !important;
}


:root {
  --font-family: "Syne", sans-serif;
  --color: #F4CE14
}

.nav-menu.active .navbar-subscribe p{
  color: black !important;
}



.slide-top {
	-webkit-animation: slide-top 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

}

 @-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
  }
}

@media screen and (max-width: 500px) {
  .home .category-content-container {
    margin: 0 0rem 1rem !important;  
  }
}


