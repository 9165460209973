
.popular-image{
    width: 100%;
    height: 650px;
}
.popular-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.popularcards-content p{
    font-family: "inter";
    font-size: 14px;
    line-height: 1.6rem;
    font-weight: 300;
    margin: 1rem 0 0;
    padding: 0;
}
.postcards h1{
    transition: all 0.3s ease;
}
.postcards:hover h1{
    color: var(--color);
    transition: all 0.3s ease;
}
@media screen and (max-width: 1500px) {
    .popular-image{
        width: 100%;
        height: 500px;
    }
}
@media screen and (max-width: 1200px) {
    .popular-image{
        width: 100%;
        height: 350px;
    }
    .popularcards-content p{
        font-family: "inter";
        font-size: 14px;
        line-height: 1.6rem;
        font-weight: 300;
        margin: 0.5rem 0 0;
        padding: 0;
    }
}

@media screen and (max-width: 800px) {
    .popular-image{
        width: 100%;
        height: 300px;
    }
    .popularcards-content p{
        font-family: "inter";
        font-size: 12px;
        line-height: 1.4rem;
        font-weight: 300;
        margin: 0.5rem 0 0;
        padding: 0;
    }
}

@media screen and (max-width: 500px) {
    .popular-image{
        width: 100%;
        height: 250px;
    }
    .popularcards-content p{
        font-family: "inter";
        font-size: 14px;
        line-height: 1.6rem;
        font-weight: 300;
        margin: 0.5rem 0 0;
        padding: 0;
    }
    .popularcards-title{
        padding: 2rem 1rem 0;
    }
}