.navbar{
  width: 100%;
background-color: black
}
h1{
  padding: 0;
  margin: 0;
}
h3{
  padding: 0;
  margin: 0;
}
p{
  padding: 0;
  margin: 0;
}

.navbar-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2.5rem 3rem;
  width: 100%;

}
.navbar-subscribe{
  border: 1px solid white;
  width: 142px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 0.1rem;
  text-decoration: none;
  color: black;
 
}
.navbar-subscribe:hover {
  background-color: var(--color);
  width: 142px;
  height: 40px;
  border: 0;
  color: white;
  transition: color 0.2s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navbar-subscribe p{
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.navbar-title{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.navbar-title h1{
  font-family: var(--font-family);
  font-size: 50px;
  margin: -0.5rem 0rem;
  cursor: pointer;
}
.navbar-logo{
  width: 270px;
  height: 50px;
  cursor: pointer;
}
.navbar-logo img{
  width: 100%;
  height: 100%;
}
.navbar-title p{
  font-family: var(--font-family);
  font-size: 23px;
  font-weight: 400;
}
.navbar-menu{
  cursor: pointer;
}
.navbar-menu h3{
  font-family: var(--font-family);
  font-size: 27px;
  margin: 0.5rem 0rem;
  transition: all 0.5s;
  color: white;
}
.navbar-menu h3:hover{
  color: var(--color);
}

.navbar-menu {
  cursor: pointer; /* Change cursor to pointer on hover */
}

.navbar-dropdown {
  display: none; /* Initially hide the dropdown */
}

.navbar-dropdown.is-active {
  display: block; /* Show the dropdown when is-active class is present */
  background-color: black;
  height: 100vh;
  color: white;
  position: absolute;
  top: 0;
  width: 100%;
}
.navbar-main-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 9rem;
}

.navbar-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.navbar-header h1{
  font-size: 55px;
  font-family: var(--font-family);
  cursor: pointer;
}
.navbar-header h3{
  font-family: var(--font-family);
  font-size: 24px;
  cursor: pointer;
}
.navbar-header h1:hover{
  color: var(--color);

}
.navbar-header h3:hover{
  color: var(--color);

}
.navbar-menus-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch; 
  width: 100%;
  margin-top: 8rem;
}
.navbar-menus{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  flex: 1.5;
}
.navbar-menu-socials{
  grid-column-start: 2;
  grid-column-end: 4;
}

.navbar-menus .links{
  color: white;
  text-decoration: none;
  border: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.5rem;
  font-family: "inter";
}
.navbar-menus .links:hover{ 
  color: var(--color);
  border: 1px solid var(--color)
}
.navbar-menu-socials{
  border: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}
.navbar-menu-socials a{
  color: white;
  cursor: pointer;
}
.navbar-menu-socials a:hover{
  color: var(--color);
}
.navbar-menu-cta{
  width: 100%;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2.5rem 2rem;
  margin-left: 2rem;
  flex: 1;
}
.navbar-menu-cta h1{
  font-weight: 400 !important;
  font-family: "inter";
}
.cta-copy{
  width: 100%;
  margin-top: 8rem;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  align-items: center !important;

}

.sub{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.sub-image{
width: 45%;
height: 570px;
}
.sub-image img{
width: 100%;
height: 100%;
object-fit: cover;
border-top-left-radius: 6px ;
border-bottom-left-radius: 6px;
}
.sub-text-container{
width: 55%;
padding: 1rem 2rem;
height: 570px;
}
.sub-button-container{
width: 100%;
display: flex;
align-items: flex-end;
justify-content: flex-end;
margin-bottom: 2rem;
}
.sub-button{
width: 25px;
height: 25px;
cursor: pointer;
}
.sub-button2{
width: 25px;
height: 25px;
cursor: pointer;
}
.sub-button :hover{
color: var(--color);
}
.sub-text{

}
.sub-text h1{
font-family: "inter";
font-size: 38px;
font-weight: 700;
margin-bottom: 1rem;
}
.sub-text p{
font-family: "inter";
font-size: 15px;
line-height: 1.6rem;
margin-bottom: 2rem;
font-weight: 400;
}
.sub-form{

}
.sub-input{
margin-bottom: 2rem;
}
.sub-input input{
border: 0;
outline: none;
border-bottom: 1px solid grey;
width: 100%;
color: black;
padding: 0.5rem;
}
.sub-input-button{
background-color: var(--color);
border-radius: 2rem;
padding: 0.5rem;
color: white;
font-family: "inter";
font-size: 14px;
letter-spacing: 1px;
display: flex;
justify-content: center;
margin-top: 1rem;
cursor: pointer;
border: 1px solid var(--color);
transition: all 0.5s ease;
}
.sub-input-button:hover{
background-color: white;
color: var(--color);
border: 1px solid var(--color);
transition: all 0.5s ease;
}
.sub-footer{
margin-top: 1rem;
}
.sub-footer P{
font-family: "inter";
font-size: 14px;
line-height: 1.6rem;
margin-bottom: 0;
font-weight: 400;
}


.modal-body {
position: relative;
flex: 1 1 auto;
padding: 0;
}

.navbar-mobile{
display: none;
}

@media screen and (max-width: 1200px) {
.navbar{
  width: 100%;
}
h1{
  padding: 0;
  margin: 0;
}
h3{
  padding: 0;
  margin: 0;
}
p{
  padding: 0;
  margin: 0;
}

.navbar-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem 3rem ;
  width: 100%;

}
.navbar-subscribe{
  width: 122px;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 0.45rem;
  text-decoration: none;
  color: black;
 
}
.navbar-subscribe:hover {
  width: 125px;
  height: 37px;
  border: 0;
  color: white;
  transition: color 0.2s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
}
.navbar-logo{
  width: 235px;
  height: 50px;
  cursor: pointer;
}

.navbar-subscribe p{
  font-family: "Inter";
  font-size: 14px;
}
.navbar-title{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.navbar-title h1{
  font-family: var(--font-family);
  font-size: 43px;
  margin: 0rem 0rem;
  cursor: pointer;
}
.navbar-title p{
  font-family: var(--font-family);
  font-size: 19px;
  font-weight: 400;
}
.navbar-menu{
  cursor: pointer;
}
.navbar-menu h3{
  font-family: var(--font-family);
  font-size: 21px;
  margin: 0.75rem 0rem;
  transition: all 0.5s;
}
.navbar-menu h3:hover{
  color: var(--color);
}

.navbar-menu {
  cursor: pointer; /* Change cursor to pointer on hover */
}

.navbar-dropdown {
  display: none; /* Initially hide the dropdown */
}

.navbar-dropdown.is-active {
  display: block; /* Show the dropdown when is-active class is present */
  background-color: black;
  height: 100vh;
  color: white;
  position: absolute;
  top: 0;
  width: 100%;
}
.navbar-main-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 3rem;
}

.navbar-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.navbar-header h1{
  font-size: 55px;
  font-family: var(--font-family);
  cursor: pointer;
}
.navbar-header h3{
  font-family: var(--font-family);
  font-size: 24px;
  cursor: pointer;
}
.navbar-header h1:hover{
  color: var(--color);

}
.navbar-header h3:hover{
  color: var(--color);

}
.navbar-menus-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch; 
  width: 100%;
  margin-top: 8rem;
}
.navbar-menus{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  flex: 1.5;
}
.navbar-menu-socials{
  grid-column-start: 2;
  grid-column-end: 4;
}

.navbar-menus .links{
  color: white;
  text-decoration: none;
  border: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.2rem;
}
.navbar-menus .links:hover{ 
  color: var(--color);
  border: 1px solid var(--color)
}
.navbar-menu-socials{
  border: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}
.navbar-menu-socials a{
  color: white;
  cursor: pointer;
}
.navbar-menu-socials a:hover{
  color: var(--color);
}
.navbar-menu-cta{
  width: 100%;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2.5rem 2rem;
  margin-left: 1rem;
  flex: 1;
}
.cta-copy{
  width: 100%;
  margin-top: 8rem;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  align-items: center !important;

}

.sub{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.sub-image{
width: 45%;
height: 550px;
}
.sub-image img{
width: 100%;
height: 100%;
object-fit: cover;
border-top-left-radius: 6px ;
border-bottom-left-radius: 6px;
}
.sub-text-container{
width: 55%;
padding: 1rem 2rem;
height: 550px;
}
.sub-button-container{
width: 100%;
display: flex;
align-items: flex-end;
justify-content: flex-end;
margin-bottom: 2rem;
}
.sub-button{
width: 25px;
height: 25px;
cursor: pointer;
}
.sub-button2{
width: 25px;
height: 25px;
cursor: pointer;
}
.sub-button :hover{
color: var(--color);
}
.sub-text{

}
.sub-text h1{
font-weight: 700;
}
.sub-text p{
font-family: "inter";
font-size: 15px;
line-height: 1.6rem;
margin-bottom: 2rem;
font-weight: 400;
}
.sub-form{

}
.sub-input{
margin-bottom: 2rem;
}
.sub-input input{
border: 0;
outline: none;
border-bottom: 1px solid grey;
width: 100%;
padding: 0.5rem;
}
.sub-input-button{
background-color: var(--color);
border-radius: 2rem;
padding: 0.5rem;
color: white;
letter-spacing: 1px;
display: flex;
justify-content: center;
margin-top: 1rem;
cursor: pointer;
border: 1px solid var(--color);
transition: all 0.5s ease;
}
.sub-input-button:hover{
background-color: white;
color: var(--color);
border: 1px solid var(--color);
transition: all 0.5s ease;
}
.sub-footer{
margin-top: 1rem;
}
.sub-footer P{
font-family: "inter";
font-size: 14px;
line-height: 1.6rem;
margin-bottom: 0;
font-weight: 400;
}


.modal-body {
position: relative;
flex: 1 1 auto;
padding: 0;
}
}

@media (max-width: 960px) {
.navbar{
  padding: 0;
}
.navbar-container{
  display: none;

}
.navbar-mobile {
  display: block;
  position: relative; 
  width: 100%;
  background: linear-gradient(
  90deg,
  black 0%,
  black 100%
);
height: 80px;
display: flex;
justify-content: space-between;
align-items: center;
font-size: 1.3rem;

}
.navbar-subscribe{
  border: 1px solid var(--color);
  width: 142px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 0.1rem;
  text-decoration: none;
  color: black;
 
}
.navbar-subscribe:hover {
  background-color: var(--color);
  width: 142px;
  height: 40px;
  border: 0;
  color: var(--color);
  transition: color 0.2s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navbar-subscribe p{
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  color: black;
}

.no-scroll {
  overflow: hidden;
}
.navbar-logo2{
  width: 200px;
  height: 50px;
  margin-left: 1rem;
}
.navbar-logo2 img{
  width: 100%;
  height: 100%;

}
.nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 80px;
  left: -100%;
  opacity: 1;
  transition: all 0.5s ease;
  overflow-y: auto;
  padding: 2rem 0 6rem;
}

.nav-menu.active {
  background: white;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
  

}
ul{
padding: 2rem 0;
}
li{
list-style: none;
}

.nav-links {
  text-align: center;
  padding: 1rem;
  width: 100%;
  display: table;
  text-decoration: none;
  color: black;
}

.nav-links:hover {
  color: var(--color);
  border-radius: 0;
}

.navbar-logo {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 1rem;
  transform: translate(25%, 50%);
}

.navbar-logo {
  margin-top: 0.75rem;
  color: white;
}

.navbar-logo:hover {
  color: var(--color);
}

.menu-icon {
  display: block;
  position: absolute;
  top: -0.15rem;
  right: 1rem;
  transform: translate(-100%, 60%);
  font-size: 1.8rem;
  cursor: pointer;
  color: white;
}
.menu-icon:hover {
  color: var(--color);
}
.navbar-menu-socials-mobile{
  margin-top: -0.5rem;
  padding:2rem 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.cta-mobile{
  color: var(--color);
  font-size: 23px !important;
  border: 1px solid var(--color);
  padding: 0.5rem;
  width: 35px;
  height: 35px;
}
.sub{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.sub-image{
width: 100%;
height: 250px;
}
.sub-image img{
width: 100%;
height: 100%;
object-fit: cover;
border-top-left-radius: 6px ;
border-bottom-left-radius: 0px;
border-top-right-radius: 6px;
}
.sub-text-container{
width: 100%;
padding: 1rem 2rem;
height: 100%;
}
.sub-button-container{
width: 100%;
display: flex;
align-items: flex-end;
justify-content: flex-end;
margin-bottom: 2rem;
}
.sub-button{
width: 25px;
height: 25px;
cursor: pointer;
}
.sub-button2{
width: 25px;
height: 25px;
cursor: pointer;
}
.sub-button :hover{
color: var(--color);
}
.sub-text{

}
.sub-text h1{
font-size: 38px;
font-weight: 700;
}
.sub-text p{
font-family: "inter";
font-size: 15px;
line-height: 1.6rem;
margin-bottom: 2rem;
font-weight: 400;
}
.sub-form{

}
.sub-input{
margin-bottom: 2rem;
}
.sub-input input{
border: 0;
outline: none;
border-bottom: 1px solid grey;
width: 100%;
padding: 0.5rem;
}
.sub-input-button{
background-color: var(--color);
border-radius: 2rem;
padding: 0.5rem;
color: white;
letter-spacing: 1px;
display: flex;
justify-content: center;
margin-top: 1rem;
cursor: pointer;
border: 1px solid var(--color);
transition: all 0.5s ease;
}
.sub-input-button:hover{
background-color: white;
color: var(--color);
border: 1px solid var(--color);
transition: all 0.5s ease;
}
.sub-footer{
margin-top: 1rem;
}
.sub-footer P{
font-family: "inter";
font-size: 14px;
line-height: 1.6rem;
margin-bottom: 0;
font-weight: 400;
}


.modal-body {
position: relative;
flex: 1 1 auto;
padding: 0;
}
}


@media screen and (max-width: 500px) {
  .navbar-logo2{
    width: 200px;
    height: 40px;
    margin-left: 0.4rem;
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: -0.1rem;
    right: -0.5rem;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: white;
  }
  .nav-menu.active{
    padding-bottom: 10rem;
  }
}
