.postcards{
    /* background-color: red; */
}

.postcards-image{
    width: 100%;
    height: 100%;
}
.postcards-image img{ 
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.postcards-title h1{
    font-family: "inter";
    font-size: 19px;
    line-height: 1.7rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
}
.postCard-spacing ul li {
    margin: 1.5rem 0;
    font-size: 12px;
    line-height: 1rem;
    color: grey;
}
.postCard-spacing ul span li {
    color: grey;
}

@media screen and (max-width: 1200px) {
    .postcards{
        /* background-color: red; */
    }
    .postcards-image{
        width: 100%;
        height: 100%;
    }
    .postcards-image img{ 
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .postcards-title h1{
        font-size: 16px;
        line-height: 1.5rem;
        font-weight: 600;
        margin: 0;
        padding: 0;
    }
    .postCard-spacing ul li {
        margin: 1rem 0;
        font-size: 12px;
        color: grey;
    }
    .postCard-spacing ul span li {
        color: grey;
    }
}

@media screen and (max-width: 500px) {
    .postcards{
        /*margin-bottom: 1rem;*/
    }
    .postcards ul{
        margin: 0;
        padding: 0;
    }
    .postcards-image{
        width: 100%;
        height: 250px;
    }
    .postcards-image img{ 
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .postcards-title-container{
        padding: 2rem 1rem 0.2rem;
    }
    .postcards-title{
    }
    .postcards-title h1{
        font-size: 19px;
        line-height: 1.8rem;
        font-weight: 600;
        margin: 0;
        padding: 0;
        max-width: 100%;
    }

    .postCard-spacing ul li {
        margin: 0rem  0 1rem;
        font-size: 12px;
        color: grey;
    }
    .postCard-spacing ul span li {
        color: grey;
    }
}


.truncate {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}