.about-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;
    padding: 4rem 3rem;
    background-color: var(--color);

}
p{
    padding: 0;
}
.about-image{
    width: 100%;
    height: 80vh !important;
}
.about-image img{
    width: 100%;
    height: 100%;
}
.about-content-container{
    padding: 0rem 0;
}
.about-content{

}

.about-content h1{
    font-family: "inter";
    font-size: 27px;
    font-weight: 800;
    margin-bottom: 1rem;
}
.about-content-writeup{

}
.about-content-writeup h3{
    font-family: "inter";
    font-size: 19px;
    font-weight: 600;
}
.about-content-writeup p{
    font-family: "inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5rem;
    margin: 1rem 0;
}
.about-subcribe-container{
    margin-top: 2rem;
    background-color: black;
    padding: 2rem;
}
.about-subcribe-container h3{
    font-family: "inter";
    font-size: 19px;
    font-weight: 600;
    color: var(--color);
}
.about-subcribe-container p{
    font-family: "inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5rem;
    margin: 1rem 0;
    color: white;
}

.sub-btn{
    margin-top: 2rem;
}

@media screen and (max-width: 960px) {
    .about-container{
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 30px;
        padding: 2rem;
        background-color: var(--color);
    
    }
    .about-image{
        width: 100%;
        height: 400px;
    }
    .about-image img{
        width: 100%;
        height: 100%;
    }
    .about-content-container{
        padding: 0rem 0;
    }
    .about-content{
    
    }
    
    .about-content h1{
        font-family: "inter";
        font-size: 27px;
        font-weight: 800;
        margin-bottom: 1rem;
    }
    .about-content-writeup{
    
    }
    .about-content-writeup h3{
        font-family: "inter";
        font-size: 19px;
        font-weight: 600;
    }
    .about-content-writeup p{
        font-family: "inter";
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5rem;
        margin: 1rem 0;
    }
    .about-subcribe-container{
        margin-top: 2rem;
        background-color: black;
        padding: 2rem;
    }
    .about-subcribe-container h3{
        font-family: "inter";
        font-size: 19px;
        font-weight: 600;
        color: var(--color);
    }
    .about-subcribe-container p{
        font-family: "inter";
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5rem;
        margin: 1rem 0;
        color: white;
    }
    
    .sub-btn{
        margin-top: 2rem;
    }
}
@media screen and (max-width: 500px) {
    .about-container{
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 30px;
        padding: 1rem;
        background-color: var(--color);
    
    }
    .about-image{
        width: 100%;
        height: 400px;
    }
    .about-image img{
        width: 100%;
        height: 100%;
    }
    .about-content-container{
        padding: 0rem 0;
    }
    .about-content{
    
    }
    
    .about-content h1{
        font-family: "inter";
        font-size: 27px;
        font-weight: 800;
        margin-bottom: 1rem;
    }
    .about-content-writeup{
    
    }
    .about-content-writeup h3{
        font-family: "inter";
        font-size: 19px;
        font-weight: 600;
    }
    .about-content-writeup p{
        font-family: "inter";
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5rem;
        margin: 1rem 0;
    }
    .about-subcribe-container{
        margin-top: 2rem;
        background-color: black;
        padding: 1.5rem;
    }
    .about-subcribe-container h3{
        font-family: "inter";
        font-size: 19px;
        font-weight: 600;
        color: var(--color);
    }
    .about-subcribe-container p{
        font-family: "inter";
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5rem;
        margin: 1rem 0;

        color: white;
    }
    
    .sub-btn{
        margin-top: 2rem;
    }
}