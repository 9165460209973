.all-container{
    padding: 0em 0;
}
.all-title{
    display: flex;
    justify-content: center;
    align-items: center;
}
.all-title h1{
    font-family: "inter";
    font-size: 26px;
    font-weight: 200;
}

@media screen and (max-width: 960px) {
    .all-container{
        padding: 0rem 0;
    }
}
