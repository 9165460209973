
.footer-container{
    background-color: black;
    padding: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}
.footer-copyright{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.footer-copyright p{
    color: white;
    font-family: "inter";
    font-size: 14px;
    line-height: 2rem;
    font-weight: 200;
}
.footer-title{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}
.footer-logo{
    width: 280px;
    height: 50px;
    margin-left: -0.5rem;
}
.footer-logo img{
    width: 100%;
    height: 100%; 
}
.footer-title h1{
    color: white;
    font-family: var(--font-family);
    font-size: 33px;
}
.footer-socials{
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
}
.footer-socials a{
    color: white;
}
.footer-socials-space{
    font-size: 19px;
    margin: 0 1.2rem;
    cursor: pointer;
}
.footer-socials-space:hover {
    color: var(--color);
    cursor: pointer;
}

@media screen  and (max-width: 1200px){
    .footer-container{
        background-color: black;
        padding: 3rem 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
    }
    .footer-logo{
        width: 245px;
        height: 50px;
        margin-left: -0.5rem;
    }
}

@media screen and (max-width: 960px) {
    
.footer-container{
    background-color: black;
    padding: 3.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    
}
.footer-copyright{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.footer-copyright p{
    color: white;
    font-family: "inter";
    font-size: 12px;
    line-height: 2rem;
    font-weight: 200;
}
.footer-title{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}
.footer-title h1{
    color: white;
    font-family: var(--font-family);
    font-size: 27px;
}
.footer-socials{
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
margin: 2rem 0;
}
.footer-socials a{
    color: white;
}
.footer-socials-space{
    font-size: 16px;
    margin-right: 1rem;

}
}
@media screen and (max-width: 500px) {
    
    .footer-container{
        background-color: black;
        padding: 3rem 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        
    }
    .footer-copyright{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .footer-copyright p{
        color: white;
        font-family: "inter";
        font-size: 14px;
        line-height: 2rem;
        font-weight: 200;
    }
    .footer-title{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    }
    .footer-title h1{
        color: white;
        font-family: var(--font-family);
        font-size: 27px;
    }
    .footer-socials{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 2rem 0 2.5rem;
    }
    .footer-socials a{
        color: white;
    }
    .footer-socials-space{
        font-size: 19px;
        margin: 0 1rem;
    
    }
    .footer-logo{
        width: 215px;
        height: 50px;
        margin-left: -0.5rem;
    }
    }