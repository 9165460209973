.category-card-container{

}
.category-menu-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
img{
    object-fit: cover;
}
.category-menu-title{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items:center;
    width: 100%;
    padding: 1.5rem 0;
    border-top: 2px solid rgb(206, 206, 206);
    border-bottom: 1px solid rgb(224, 224, 224);
    cursor: pointer;
    transition: all 0.3s linear;
}
.category-menu-title:hover .category-menu-title-icon, 
.category-menu-title:hover h1 {
    margin-left: 0.8rem;
    transition: all 0.3s linear;
    color: var(--color);
}
.category-menu-title h1{
    font-family: "inter";
    font-size: 21px;
    font-weight: 600;
    margin-right: 0.8rem;
    margin-left: 0.8rem;
    transition: all 0.3s linear;

}
.category-menu-title-icon{
    margin-top: 0.1rem;
    transition: all 0.3s linear;
}
.category-menu-content-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:flex-start;
    width: 100%;
    padding: 1.5rem 0;
}
.category-menu-content-container2{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items:flex-start;
    width: 100%;
    padding: 1.5rem 0;
}
.category-menu-content-left{
    display: grid;
    grid-template-columns: 1fr;
    width: 70%;
    padding-right: 1.5rem;
    border-right: 1px solid rgb(224, 224, 224);

}
.category2-menu-content-left{
    display: grid;
    grid-template-columns: 1fr;
    width: 70%;
    padding-left: 1.5rem;
    border-left: 1px solid rgb(224, 224, 224);
}
.category-menu-content-left a{
    width: 100%;
}
.category-menu-content-left-top{
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items:center;
border-bottom: 1px solid rgb(224, 224, 224) ;
padding-bottom: 1rem;
}
.category-menu-content-left-top-image{
    width: 100%;
    height: 700px;
}
.category-menu-content-left-top-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}
.category-menu-content-left-top-text{
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;

}
.category-menu-content-left-top-text h3{
    font-family: "inter";
    font-size: 23px;
    font-weight: 600;
    text-align: center;
}
.category-menu-content-left-top:hover h3{
    color: var(--color);
    cursor: pointer;
}
.category-menu-content-left-top-text p{
    font-family: "inter";
    font-size: 14px;
    font-weight: 400;
    margin-top: 0.3rem;
}
.category-menu-content-left-bottom{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 1.5rem 0;
}
.category-menu-content-left-bottom-left-container{
    display: grid;
    grid-template-columns: 1fr;
    flex: 1;
    border-right: 1px solid rgb(224, 224, 224);
  
}
.category-menu-content-left-bottom-left{
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 20px;
    margin-bottom: 1.5rem;
}
.category-menu-content-left-bottom-left-img{
    width: 100%;
    height: 100px;
    margin-right: 1rem;
    cursor: pointer;
}
.category-menu-content-left-bottom-left-img img{
    width: 100%;
    height: 100%; 
    object-fit: cover;
}
.category-menu-content-left-bottom-left-text{
}

.category-menu-content-left-bottom-left-text h1{
    font-family: "inter";
    font-size: 14px;
    line-height: 1.3rem;
    font-weight: 600;
    max-width: 250px;
    margin-bottom: 0.5rem;
}
.category-menu-content-left-bottom-left:hover h1 {
    cursor: pointer;
    color: var(--color);
}
.category-menu-content-left-bottom-left-text p{
    font-family: "inter";
    font-size: 14px;
    font-weight: 300;
}
.category-menu-content-left-bottom-right-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    flex: 1;
    margin-left: 2rem;
}
.category-menu-content-right-container{
    display: grid;
    grid-template-columns: 1fr;
    width: 30%;
    margin-left: 1.5rem;
}
.category2-menu-content-right-container{
    display: grid;
    grid-template-columns: 1fr;
    width: 30%;
    margin-right: 1.5rem;
}
.category-menu-content-right{
    width: 100%;
    margin-bottom: 2rem;
}
.category-menu-content-right:hover h1 {
cursor: pointer;
color: var(--color);
}
.category-menu-content-right img{
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.category-menu-content-right h1{
    font-family: "inter";
    line-height: 1.8rem;
    font-size: 19px;
    font-weight: 600;
    margin-top: 1.5rem;
}
.category-menu-content-right p{
    font-family: "inter";
    font-size: 14px;
    font-weight: 300;
}


.category-menu-containe-list-container{
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 30px;
}
.category-menu-container-list{

}
.category-menu-container-advert-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.category-menu-container-advert{
    width: 80%;
    height: 300px;
}

.advert{
    margin: 0 0 2rem !important;
}

@media screen and (max-width: 1200px) {
    .category-card-container{

    }
    .category-menu-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .category-menu-title{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items:center;
        width: 100%;
        padding: 1rem 0;
        cursor: pointer;
        transition: all 0.3s linear;
    }
    .category-menu-title:hover .category-menu-title-icon{
        margin-left: 1rem;
        transition: all 0.3s linear;
    }
    .category-menu-title h1{
        font-size: 21px;
        font-weight: 600;
        margin-right: 1rem;
    }
    .category-menu-title-icon{
    
    }
    .category-menu-content-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items:flex-start;
        width: 100%;
        padding: 1rem 0;
    }
    .category-menu-content-container2{
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items:flex-start;
        width: 100%;
        padding: 1rem 0;
    }
    .category-menu-content-left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        width: 70%;
        padding-right: 1rem;
        border-right: 1px solid rgb(224, 224, 224);
    }
    .category2-menu-content-left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        width: 70%;
        padding-left: 1rem;
        border-left: 1px solid rgb(224, 224, 224);
    }
    .category-menu-content-left-top{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        border-bottom: 1px solid rgb(224, 224, 224) ;
        padding-bottom: 1rem;
    }
    .category-menu-content-left-top-image{
        width: 100%;
        height: 600px;
    }
    .category-menu-content-left-top-image img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .category-menu-content-left-top-text{
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
    
    }
    .category-menu-content-left-top-text h3{
        font-size: 19px;
        font-weight: 600;
        line-height: 1.75rem;
        max-width: 400px;
       
    }
    .category-menu-content-left-top-text p{
        font-weight: 400;
        margin-top: 0.3rem;
    }
    .category-menu-content-left-bottom{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items:center;
        width: 100%;
        padding: 1.5rem 0;
    }
    .category-menu-content-left-bottom-left-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:flex-start;
        flex: 1;
        border-right: 1px solid rgb(224, 224, 224);
      
    }
    .category-menu-content-left-bottom-left{
        display: grid;
        column-gap: 10px;
        margin-bottom: 2rem;
    }
    .category-menu-content-left-bottom-left-img{
        width: 100%;
        height: 100px;
    }
    .category-menu-content-left-bottom-left-img img{
        width: 100%;
        height: 100%; 
    }
    .category-menu-content-left-bottom-left-text{
    }
    .category-menu-content-left-bottom-left-text h1{
        font-size: 13px;
        font-weight: 600;
        max-width: 250px;
    }
    .category-menu-content-left-bottom-left-text p{
        font-size: 10px;
        margin-top: 0rem;
        font-weight: 300;
    }
    .category-menu-content-left-bottom-right-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:flex-start;
        flex: 1;
        margin-left: 2rem;
    }
    .category-menu-content-right-container{
        width: 30%;
        margin-left: 1rem;
    }
    .category2-menu-content-right-container{
        width: 30%;
        margin-right: 1.5rem;
    }
    .category-menu-content-right{
    
        width: 100%;
        margin-bottom: 2rem;
    }
    .category-menu-content-right img{
        width: 100%;
        height: 250px;
        object-fit: cover;
    }
    .category-menu-content-right h1{
        font-size: 16px;
        font-weight: 600;
        max-width: 100%;
        margin-top: 1.5rem;
    }
    .category-menu-content-right p{
        font-size: 13px;
        font-weight: 300;
    }
    
}

@media screen and (max-width: 960px) {
    .category-card-container{

    }

.category-menu-containe-list-container{
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
}
.category-menu-container-list{

}
.category-menu-container-advert-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.category-menu-container-advert{
    width: 100%;
    height: 300px;
}
    .category-menu-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .category-menu-title{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items:center;
        width: 100%;
        padding: 1rem 0;
        cursor: pointer;
        transition: all 0.3s linear;
    }
    .category-menu-title:hover .category-menu-title-icon{
        margin-left: 1rem;
        transition: all 0.3s linear;
    }
    .category-menu-title h1{
        font-size: 21px;
        font-weight: 600;
        margin-right: 1rem;
    }
    .category-menu-title-icon{
    
    }
    .category-menu-content-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items:flex-start;
        width: 100%;
        padding: 1rem 0;
    }
    .category-menu-content-container2{
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items:flex-start;
        width: 100%;
        padding: 1rem 0;
    }
    .category-menu-content-left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        width: 70%;
        padding-right: 1rem;
        border-right: 1px solid rgb(224, 224, 224);
    }
    .category2-menu-content-left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        width: 70%;
        padding-left: 1rem;
        border-left: 1px solid rgb(224, 224, 224);
    }
    .category-menu-content-left-top{
    
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    border-bottom: 1px solid rgb(224, 224, 224) ;
    padding-bottom: 1rem;
    }
    .category-menu-content-left-top-image{
        width: 100%;
        height: 400px;
    }
    .category-menu-content-left-top-image img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .category-menu-content-left-top-text{
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
    
    }
    .category-menu-content-left-top-text h3{
        font-size: 19px;
        font-weight: 600;
       
    }
    .category-menu-content-left-top-text p{
        font-size: 13px;
        font-weight: 400;
        margin-top: 0.3rem;
    }
    .category-menu-content-left-bottom{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items:flex-start;
        width: 100%;
        padding: 1.5rem 0;
    }
    .category-menu-content-left-bottom-left-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:flex-start;
        flex: 1;
        border-right: 1px solid rgb(224, 224, 224);
      
    }
    .category-menu-content-left-bottom-left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:flex-start;
        margin-bottom: 1.5rem;
    }
    .category-menu-content-left-bottom-left-img{
        width: 90%;
        height: 150px;
        margin-right: 0rem;
        margin-bottom: 0.5rem;

    }
    .category-menu-content-left-bottom-left-img img{
        width: 100%;
        height: 100%; 
    }
    .category-menu-content-left-bottom-left-text{
    }
    .category-menu-content-left-bottom-left-text h1{
        font-size: 14px;
        font-weight: 600;
        max-width: 100%;
        margin-bottom: 0.4rem;
    }
    .category-menu-content-left-bottom-left-text p{
        font-size: 12px;
        margin-top: 0rem;
        line-height: 1rem;
        font-weight: 300;
    }
    .category-menu-content-left-bottom-right-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:flex-start;
        flex: 1;
        margin-left: 1rem;
    }
    .category-menu-content-right-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        width: 30%;
        margin-left: 1rem;
    }
    .category2-menu-content-right-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        width: 30%;
        margin-right: 1.5rem;
    }
    .category-menu-content-right{
    
        width: 100%;
        margin-bottom: 1.5rem;
    }
    .category-menu-content-right img{
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
    .category-menu-content-right h1{
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5rem;
        margin-top: 1.5rem;
    }
    .category-menu-content-right p{
        font-size: 13px;
        font-weight: 300;
    }
    
}

@media screen and (max-width: 500px) {
    .category-card-container{

    }
    .category-menu-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .category-menu-title{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items:center;
        width: 100%;
        padding: 1.5rem 0;
        cursor: pointer;
        transition: all 0.3s linear;
    }
    .category-menu-title:hover .category-menu-title-icon{
        margin-left: 1rem;
        transition: all 0.3s linear;
    }
    .category-menu-title h1{
        font-size: 19px;
        font-weight: 600;
        margin-left: 0;
        margin-right: 0.5rem;
    }
    .category-menu-title-icon{
    
    }
    .category-menu-content-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:flex-start;
        width: 100%;
        padding: 1.5rem 0;
    }
    .category-menu-content-container2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:flex-start;
        width: 100%;
        padding: 1.5rem 0;
    }
    .category-menu-content-left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        width: 100%;
        padding-right: 0rem;
        border-right: none;
    }
    .category2-menu-content-left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        width: 100%;
        padding-left: 0rem;
        border-left: 0;
    }
    .category-menu-content-left-top{
    
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    border-bottom: none ;
    padding-bottom: 1rem;
    }
    .category-menu-content-left-top-image{
        width: 100%;
        height: 300px;
    }
    .category-menu-content-left-top-image img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .category-menu-content-left-top-text{
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
    
    }
    .category-menu-content-left-top-text h3{
        font-size: 21px;
        font-weight: 600;
        text-align: center;
       
    }
    .category-menu-content-left-top-text p{
        font-size: 13px;
        font-weight: 400;
        margin-top: 0.3rem;
    }
    .category-menu-content-left-bottom{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items:flex-start;
        width: 100%;
        padding: 1rem 0;
    }
    .category-menu-content-left-bottom-left-container{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items:flex-start;
        width: 100%;
        border-right: 0;
        

      
    }
    .category-menu-content-left-bottom-left{
        display: grid;
        grid-template-columns: 1fr 3fr;
        margin-bottom: 0rem;
        column-gap: 5px;
        padding: 1.5rem 0;
        border-top: 1px solid rgb(224, 224, 224);
        width: 100%;
    }
    .category-menu-content-left-bottom-left-img{
        width: 100px;
        height: 100px;
        margin-right: 0rem;
    }
    .category-menu-content-left-bottom-left-img img{
        width: 100%;
        height: 100%; 
    }
    .category-menu-content-left-bottom-left-text{
        margin-left: 1rem;
    }
    .category-menu-content-left-bottom-left-text h1{
        font-size: 14px;
        line-height: 1.3rem;
        font-weight: 600;
        max-width: 100%;
    }
    .category-menu-content-left-bottom-left-text p{
        font-size: 12px;
        margin-top: 0.5rem;
        line-height: 1rem;
        font-weight: 300;
        padding: 0;
    }
    .category-menu-content-left-bottom-right-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:flex-start;
        flex: 1;
        margin-left: 0rem;
        width: 100%;
    }
    .category-menu-content-right-container{
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        width: 30%;
        margin-left: 0rem;
    }
    .category2-menu-content-right-container{
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        width: 30%;
        margin-right: 0rem;
    }
    .category-menu-content-right{
    
        width: 100%;
        margin-bottom: 0rem;
        
    }
    .category-menu-content-right img{
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
    .category-menu-content-right h1{
        font-size: 16px;
        font-weight: 600;
        margin-top: 1.5rem;
    }
    .category-menu-content-right p{
        font-size: 13px;
        font-weight: 300;
    }
    
}