.container{
    padding: 0 60px 20px;
}
@media screen and (max-width: 960px) {
    .container{
        padding: 0 40px 20px;
    }
}
@media screen and (max-width: 500px) {
    .container{
        padding: 0 0px 0px;
    }
    .container h5{
        font-size: 21px;
        color: var(--color);
        font-family: "inter";
    }
    
    .container p{
        margin-top: 0.5rem;
        font-size: 16px;
        font-weight: 400;
        font-family: "inter";
    }
}