.category{

}
.category-container{
    padding:  0rem 3rem 0rem;
}
.category-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
.category-title p{
    font-family: var(--font-family);
    font-size: 23px;
    font-weight: 400;
}
.category-content-container{ 
    margin: 2rem 0 2rem;
}

@media screen and (max-width: 1200px) {
    .category-container{
        padding:  0rem 2rem 0rem;
    }
    .category-title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
    }
    .category-title p{
        font-family: var(--font-family);
        font-size: 23px;
        font-weight: 400;
    }
}
@media screen and (max-width: 960px) {
    .category-container{
        padding:  0rem 1rem 0rem;
    }
    .category-content-container{
        margin: 2rem 0 0rem;
    }
    .category-title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
    }
    .category-title p{
        font-family: var(--font-family);
        font-size: 23px;
        font-weight: 400;
    }
}
@media screen and (max-width: 500px) {
    .category-container{
        padding:  0rem 1rem 0rem;
    }
    .category-content-container{
        margin: 1.5rem 0 1.5rem;
    }
    .category-title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
    }
    .category-title p{
        font-family: var(--font-family);
        font-size: 23px;
        font-weight: 400;
    }
}

.category-content-container{
}