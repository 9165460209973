
@import url("https://assets.mlcdn.com/fonts.css?version=1717671");

/* LOADER */
.ml-form-embedSubmitLoad {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.check-row{
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-start;
}
.label-description p{
    font-family: "inter" !important;
    font-size: 13px;
    font-weight: 400;
    margin-left: 0.5rem;
}
.name-form{
    margin-bottom: 1rem;
}
.primary{
    outline: none;
    border: none;
    width: 100%;
    height: 40px;
    background-color: black;
    color: var(--color);
    margin-bottom: 1.5rem;
    font-size: 14px; 
}
.success-mes h4{
    font-family: "inter" !important;
    font-size: 33px !important;
    font-weight: 600 !important;
    text-align: center !important;
}

.g-recaptcha {
  transform: scale(1);
  -webkit-transform: scale(1);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  /* height: ; */
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.ml-form-embedSubmitLoad:after {
  content: " ";
  display: block;
  width: 11px;
  height: 11px;
  margin: 1px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-color: #ffffff #ffffff #ffffff transparent;
  animation: ml-form-embedSubmitLoad 1.2s linear infinite;
}

@keyframes ml-form-embedSubmitLoad {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#mlb2-15832814.ml-form-embedContainer {
  box-sizing: border-box;
  display: table;
  margin: 0 auto;
  position: static;
  width: 100% !important;
}

#mlb2-15832814.ml-form-embedContainer h4,
#mlb2-15832814.ml-form-embedContainer p,
#mlb2-15832814.ml-form-embedContainer span,
#mlb2-15832814.ml-form-embedContainer button {
  text-transform: none !important;
  letter-spacing: normal !important;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper {
  background-color: #F4CE14;
  border-width: 0px;
  border-color: transparent;
  border-radius: 4px;
  border-style: solid;
  box-sizing: border-box;
  display: inline-block !important;
  margin: 0;
  padding: 0;
  position: relative;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper.embedPopup,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper.embedDefault {
  width: 400px;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper.embedForm {
  max-width: 400px;
  width: 100%;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-align-left {
  text-align: left;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-align-center {
  text-align: center;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-align-default {
  display: table-cell !important;
  vertical-align: middle !important;
  text-align: center !important;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-align-right {
  text-align: right;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedHeader img {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: auto;
  margin: 0 auto !important;
  max-width: 100%;
  width: undefinedpx;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody {
  padding: 20px 20px 0 20px;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody.ml-form-embedBodyHorizontal {
  padding-bottom: 0;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedContent,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody .ml-form-successContent {
  text-align: left;
  margin: 0 0 20px 0;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedContent h4,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody .ml-form-successContent h4 {
  color: #000000;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: 400;
  margin: 0 0 10px 0;
  text-align: left;
  word-break: break-word;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedContent p,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody .ml-form-successContent p {
  color: #000000;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 10px 0;
  text-align: left;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedContent ul,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody .ml-form-successContent ul,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedContent ol,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody .ml-form-successContent ol {
  color: #000000;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedContent ol ol,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody .ml-form-successContent ol ol {
  list-style-type: lower-alpha;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedContent ol ol ol,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody .ml-form-successContent ol ol ol {
  list-style-type: lower-roman;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedContent p a,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody .ml-form-successContent p a {
  color: #000000;
  text-decoration: underline;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-block-form .ml-field-group {
  text-align: left!important;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-block-form .ml-field-group label {
  margin-bottom: 5px;
  color: #333333;
  font-size: 14px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
  display: inline-block;
  line-height: 20px;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedContent p:last-child,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody .ml-form-successContent p:last-child {
  margin: 0;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody form {
  margin: 0;
  width: 100%;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow {
  margin: 0 0 20px 0;
  width: 100%;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow {
  float: left;
}

#mlb2-158

32814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent::after {
  content: "";
  display: table;
  clear: both;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="text"],
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="email"],
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="url"],
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="tel"],
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="number"],
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="date"],
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent select,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent textarea,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent .ss-custom-select {
  display: block;
  box-sizing: border-box;
  color: #333333;
  font-size: 14px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  margin: 0;
  outline: none;
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  background-color: #FFFFFF;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='6' viewBox='0 0 12 6'%3E%3Cpath fill='%23666666' fill-rule='evenodd' d='M10.94 0L6 4.533 1.06 0 0 1.046 6 6l6-4.954z'/%3E%3C/svg%3E);
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 8px 8px;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent textarea {
  min-height: 100px;
  resize: vertical;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="text"].error,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="email"].error,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="url"].error,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="tel"].error,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="number"].error,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="date"].error,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent select.error,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent textarea.error,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent .ss-custom-select.error {
  border-color: #ff0000;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="text"].error:focus,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="email"].error:focus,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="url"].error:focus,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="tel"].error:focus,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="number"].error:focus,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent input[type="date"].error:focus,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent select.error:focus,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent textarea.error:focus,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent .ss-custom-select.error:focus {
  border-color: #ff0000;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent .ss-custom-select {
  position: relative;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent .ss-custom-select::after {
  content: "\f107";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  color: #666666;
  position: absolute;
  pointer-events: none;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
  width: 10px;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent .ss-custom-select.selectPressed::after {
  content: "\f106";
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent .ss-custom-select select {
  padding-right: 28px !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent .ml-form-checkbox {
  margin: 0;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent .ml-form-checkbox label {
  cursor: pointer;
  display: block;
  padding-left: 35px;
  position: relative;
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  line-height: 20px;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent .ml-form-checkbox label input[type="checkbox"] {
  margin: 0;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent .ml-form-checkbox label input[type="checkbox"] + .ml-form-embedCheck {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}

#mlb2-15832814.ml

-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent .ml-form-checkbox label input[type="checkbox"] + .ml-form-embedCheck::after {
  content: '';
  display: block;
  height: 5px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  width: 9px;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
  border-width: 0 0 2px 2px;
  border-style: solid;
  border-color: #FFFFFF;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent .ml-form-checkbox label input[type="checkbox"]:checked + .ml-form-embedCheck {
  background: #7E57C2;
  border-color: #7E57C2;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent .ml-form-checkbox label input[type="checkbox"]:checked + .ml-form-embedCheck::after {
  opacity: 1;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent button[type="submit"],
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent .ml-form-formFooter button[type="submit"] {
  width: auto;
  color: #FFFFFF;
  font-size: 14px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: bold;
  line-height: 20px;
  margin: 0;
  padding: 15px 30px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #7E57C2;
  background-color: #7E57C2;
  -webkit-transition: background-color 0.3s ease, border-color 0.3s ease;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent button[type="submit"]:hover,
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-formContent .ml-form-formFooter button[type="submit"]:hover {
  background-color: #6C43B1;
  border-color: #6C43B1;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-successBody h4 {
  color: #333333;
  font-size: 22px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 20px 0;
  padding: 0;
  text-align: center;
  line-height: 30px;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-successBody p {
  color: #333333;
  font-size: 14px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: normal;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  line-height: 20px;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-successBody button[type="submit"] {
  width: auto;
  color: #FFFFFF;
  font-size: 14px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: bold;
  line-height: 20px;
  margin: 0;
  padding: 15px 30px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #7E57C2;
  background-color: #7E57C2;
  -webkit-transition: background-color 0.3s ease, border-color 0.3s ease;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-successBody button[type="submit"]:hover {
  background-color: #6C43B1;
  border-color: #6C43B1;
}
#mlb2-15832814.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedContent button[type="submit"].error {
  border-color: #ff0000;
  background-color: #ff0000;
}
        @media only screen and (max-width: 400px){

    .ml-form-embedWrapper.embedDefault, .ml-form-embedWrapper.embedPopup { width: 100%!important; }
    .ml-form-formContent.horozintalForm { float: left!important; }
    .ml-form-formContent.horozintalForm .ml-form-horizontalRow { height: auto!important; width: 100%!important; float: left!important; }
    .ml-form-formContent.horozintalForm .ml-form-horizontalRow .ml-input-horizontal { width: 100%!important; }
    .ml-form-formContent.horozintalForm .ml-form-horizontalRow .ml-input-horizontal > div { padding-right: 0px!important; padding-bottom: 10px; }
    .ml-form-formContent.horozintalForm .ml-button-horizontal { width: 100%!important; }
    .ml-form-formContent.horozintalForm .ml-button-horizontal.labelsOn { padding-top: 0px!important; }

  }
